.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  .printable-invoice,
  .printable-invoice * {
    visibility: visible;
  }

  .printable-invoice {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

/* Custom Container Styles */
/* Force full width on all screens */
/* Add slight margin on the sides */
.app-container {
  width: 100%;
  max-width: 98%; /* Allow some breathing space */
  margin: 0 auto; /* Center the content */
  padding: 0 10px; /* Small padding */
}

/* Ensure Navbar and Content take proper width */
.navbar,
.container,
.container-lg,
.container-fluid {
  max-width: 100% !important; /* Same as app-container */
  width: 100% !important;
  margin: 0 auto !important; /* Center */
  padding: 2 15px !important; /* Keep a little spacing */
}

/* Slightly reduce padding on larger screens */
@media (min-width: 1600px) {
  .app-container {
    max-width: 98%; /* Slightly larger margin */
    padding: 0 30px;
  }
}
