.inventory-container {
  padding: 0 15px;
}

.inventory-col {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
}

.item-list-container {
  max-height: 600px;
  overflow-y: scroll;
}

@media (max-width: 576px) {
  .inventory-container {
    padding: 0 10px;
  }

  .inventory-col {
    margin: 0 5px;
    padding: 15px;
  }

  .item-list-container {
    max-height: 400px;
  }
}
