.home-container {
  padding: 0 15px;
}

.home-card {
  margin: 0 auto;
  max-width: 500px;
}

@media (max-width: 576px) {
  .home-container {
    padding: 0 10px;
  }

  .home-card {
    margin: 0 5px;
  }
}
